.header {
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
	position: absolute;
	width: auto;
	top: 0;
	left: 0;
	right: 0;
	padding: 0 20px;
	background-color: #000;
	background-color: rgba(0, 0, 0, 0.9);

	.logo {
		line-height: 48px;
		color: #ff9900;
		display: inline-block;
		margin-right: 30px;
		-webkit-transition: color 0.3s;
    	transition: color 0.3s;

		&:hover {
			color: #fff;
			
			span {
				color: #ff9900;
			}
		}

		span {
			color: #fff;
			margin-left: 10px;
			display: inline-block;
			-webkit-transition: color 0.3s;
    		transition: color 0.3s;
		}
	}

	.icon {
		display: block;
		position: relative;
		height: 26px;
		width: 26px;
		display: inline-block;
		padding: 11px 3px;
		line-height: 26px;

		i {
			height: 26px;
			width: 26px;
			top: 12px;
			left: 0px;
			line-height: 24px;
		}
		
		&:hover {
			
			i {
				background: #ff9900;
			}
		}
	}

	.navigation {
		float: right;

		ul {
			list-style: none;
			margin: 0 -12px 0 0;

			li {
				display: inline-block;
				margin: 0;

				a, span, strong {
					line-height: 48px;
					display: inline-block;
					padding: 0 12px;
					text-align: center;
					color: #fff;
				}
				
				a {
					-webkit-transition: color 0.2s;
    				transition: color 0.2s;
				}
				
				a:hover {
					color: #ff9900;
				}

				span {
					color: #ff9900;
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	
	.header {
		
		.logo {
			margin-right: 13px;
		}
		
		.navigation {

			ul {

				li {
	
					a, span, strong {
						padding: 0 5px;
					}
				}
			}
		}
	}
}